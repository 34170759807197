import React, { useEffect, useState } from "react";

const fileUri = process.env.REACT_APP_BACKEND_FILE_URI;

const VideoComponent = ({
  swingId,
  fileName,
  screenshot,
  className,
  style,
  componentKey,
}) => {
  const fileExtension = fileName.split(".").pop();
  const mimeTypes = {
    mp4: "video/mp4",
    webm: "video/webm",
    ogv: "video/ogg",
  };
  const mimeType = mimeTypes[fileExtension] || "video/mp4";
  const [cb, setCb] = useState(Date.now());

  useEffect(() => {
    setCb(Date.now());
  }, []);

  const videoUrl = `${fileUri}/download/${swingId}/${fileName}?cb=${cb}`;
  const posterUrl = screenshot
    ? `${fileUri}/download/${swingId}/${screenshot}`
    : `${fileUri}/download/${swingId}/${fileName.replace(/\.\w+$/, ".jpg")}`;

  return (
    <video
      controls
      className={className}
      style={style}
      key={componentKey}
      poster={posterUrl}
    >
      <source src={videoUrl} type={mimeType} />
      Your browser does not support the video tag.
    </video>
  );
};

export { VideoComponent };
