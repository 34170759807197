import { gql } from "@apollo/client";

export const ME = gql`
  query me {
    me {
      _id
      first_name
      last_name
      email
      registration_date
      last_access
      last_update
      active
      admin
      handicap
      handedness
    }
  }
`;
