import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from as apolloFrom,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getValidToken } from "./utils/jwt";

const backendUri = process.env.REACT_APP_BACKEND_URI;

const createApolloClient = () => {
  const httpLink = new HttpLink({ uri: backendUri });

  const authLink = setContext(async (_, { headers }) => {
    let accessToken = await getValidToken();
    if (accessToken) {
      return {
        headers: {
          ...headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
      };
    } else {
      return { headers: headers };
    }
  });

  return new ApolloClient({
    link: apolloFrom([authLink, httpLink]),
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;
