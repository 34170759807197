import { gql } from "@apollo/client";

export const LOGIN_USER_GOOGLE = gql`
  mutation LoginUserGoogle($token: String!) {
    loginUserGoogle(token: $token) {
      user {
        _id
        first_name
        last_name
        email
        registration_date
        last_access
        last_update
        active
        admin
      }
      accessToken
      refreshToken
    }
  }
`;
