import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { googleConfig, microsoftConfig } from "./config";

import StoreProvider from "./utils/Store";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/JWTContextGraphQL";

const clientSideEmotionCache = createEmotionCache();

const msalConfig = {
  auth: {
    clientId: microsoftConfig.clientId,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: window.location.origin + "/auth/sign-in",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <GoogleOAuthProvider clientId={googleConfig.clientId}>
      <MsalProvider instance={msalInstance}>
        <StoreProvider>
          <CacheProvider value={emotionCache}>
            <HelmetProvider>
              <Helmet
                titleTemplate="%s | Golfswings"
                defaultTitle="Golf MySwings AI Analysis & Training"
              />
              <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MuiThemeProvider theme={createTheme(theme)}>
                    <AuthProvider>{content}</AuthProvider>
                  </MuiThemeProvider>
                </LocalizationProvider>
              </Provider>
            </HelmetProvider>
          </CacheProvider>
        </StoreProvider>
      </MsalProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
