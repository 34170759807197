import React from "react";
import async from "./components/Async";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import { AuthGuard, AdminGuard } from "./components/guards/AuthGuard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Auth pages
const SignIn = async(() => import("./pages/auth/SignIn"));
const SignUp = async(() => import("./pages/auth/SignUp"));
const SignOut = async(() => import("./pages/auth/SignOut"));
const ResetPassword = async(() => import("./pages/auth/ResetPassword"));
const Page404 = async(() => import("./pages/auth/Page404"));
const Page500 = async(() => import("./pages/auth/Page500"));
// Dashboard
const Dashboard = async(() => import("./pages/dashboard"));
// Swing Report
const SwingReport = async(() => import("./pages/swingreport"));
// Lessons
const Lessons = async(() => import("./pages/golfswings/lessons"));
const Progress = async(() => import("./pages/golfswings/progress"));
const SwingHistory = async(() => import("./pages/golfswings/swinghistory"));
const SevenStages = async(() => import("./pages/golfswings/sevenstages"));
// Profile pages
const Profile = async(() => import("./pages/profile/Profile"));
const Payments = async(() => import("./pages/profile/Payments"));
const Billing = async(() => import("./pages/profile/Billing"));
const Password = async(() => import("./pages/profile/Password"));
// Admin pages
const MeasurementsAdmin = async(() => import("./pages/admin/Measurements"));
const UsersAdmin = async(() => import("./pages/admin/Users"));
const UserAdmin = async(() => import("./pages/admin/User"));
const UserSwingAdmin = async(() => import("./pages/admin/UserSwing"));
const VideoAdmin = async(() => import("./pages/admin/Video"));
// Static pages
const PrivacyPolicy = async(() => import("./pages/static/PrivacyPolicy"));
const TermsOfService = async(() => import("./pages/static/TermsOfService"));
// Video pages
const Video = async(() => import("./pages/video"));
const VideoItem = async(() => import("./pages/video/item"));

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || "";

const stripePromise = STRIPE_PUBLIC_KEY ? loadStripe(STRIPE_PUBLIC_KEY) : null;

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "lessons",
        element: <Lessons />,
      },
      {
        path: "progress",
        element: <Progress />,
      },
      {
        path: "swinghistory",
        element: <SwingHistory />,
      },
      {
        path: "sevenstages",
        element: <SevenStages />,
      },
      {
        path: "profile/edit",
        element: <Profile />,
      },
      {
        path: "profile/password",
        element: <Password />,
      },
      ...(STRIPE_PUBLIC_KEY
        ? [
            {
              path: "profile/billing",
              element: (
                <Elements stripe={stripePromise}>
                  <Billing />
                </Elements>
              ),
            },
            {
              path: "profile/payments",
              element: (
                <Elements stripe={stripePromise}>
                  <Payments />
                </Elements>
              ),
            },
          ]
        : []),
      {
        path: "video/stage/:stageID",
        element: <Video />,
      },
      {
        path: "video/stage/:stageID/:videoID",
        element: <VideoItem />,
      },
      {
        path: "report/:swingID",
        element: <SwingReport />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <AuthGuard>
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "measurements",
        element: <MeasurementsAdmin />,
      },
      {
        path: "video",
        element: <VideoAdmin />,
      },
      {
        path: "users",
        element: <UsersAdmin />,
      },
      {
        path: "users/:userId",
        element: <UserAdmin />,
      },
      {
        path: "users/:userId/:swingID",
        element: <UserSwingAdmin />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "sign-out",
        element: <SignOut />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms-of-service",
        element: <TermsOfService />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
