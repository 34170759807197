import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      _id
      first_name
      last_name
      email
      registration_date
      last_access
      last_update
      active
      admin
      handicap
      handedness
    }
  }
`;
