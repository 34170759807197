// ResetPasswordMutation.js
import { gql } from "@apollo/client";

export const CHANGE_USER_PASSWORD = gql`
  mutation ChangeUserPassword($token: String!, $password: String!) {
    changeUserPassword(token: $token, new_password: $password) {
      user {
        _id
        first_name
        last_name
        email
        registration_date
        last_access
        last_update
        active
        admin
      }
      accessToken
      refreshToken
    }
  }
`;
