import React from "react";
import { Navigate } from "react-router-dom";
import styled from "@emotion/styled";
import Loader from "../Loader";
import Page404 from "../../pages/auth/Page404";

import useAuth from "../../hooks/useAuth";

const FullscreenRoot = styled.div`
  position: fixed; // Fullscreen positioning
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1202; // Ensure it's above most other elements
`;

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isInitialized, isAuthenticated } = useAuth();
  if (!isInitialized) {
    return (
      <FullscreenRoot>
        <Loader />
      </FullscreenRoot>
    );
  }
  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

function AdminGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated || !user?.admin) {
    return <Page404 />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export { AuthGuard, AdminGuard };
