// BallToggle.js
import React from "react";
import "./BallToggle.scss"; // Make sure your SCSS file reflects the new styles

const BallToggle = ({
  currentValue,
  minValue,
  maxValue,
  minOptimalValue,
  maxOptimalValue,
}) => {
  // Calculate the percentage positions of the ball and the optimal range

  const minOptimalValueisMin = minOptimalValue === minValue;
  const maxOptimalValueisMax = maxOptimalValue === maxValue;

  let realMinValue = minValue;
  if (minOptimalValue < realMinValue) {
    realMinValue = minOptimalValue;
  }
  if (currentValue < realMinValue) {
    realMinValue = currentValue;
  }

  let realMaxValue = maxValue;
  if (maxOptimalValue > realMaxValue) {
    realMaxValue = maxOptimalValue;
  }
  if (currentValue > realMaxValue) {
    realMaxValue = currentValue;
  }

  const realMinOptimalValue = minOptimalValueisMin
    ? realMinValue
    : minOptimalValue;

  const realMaxOptimalValue = maxOptimalValueisMax
    ? realMaxValue
    : maxOptimalValue;

  const ballPositionPercent =
    ((currentValue - realMinValue) / (realMaxValue - realMinValue)) * 100;
  const optimalStartPercent =
    ((realMinOptimalValue - realMinValue) / (realMaxValue - realMinValue)) *
    100;
  const optimalEndPercent =
    ((realMaxOptimalValue - realMinValue) / (realMaxValue - realMinValue)) *
    100;

  return (
    <div className="ballToggleContainer">
      <div className="gauge">
        <div
          className="gauge__range gauge__range--optimal"
          style={{
            left: `${optimalStartPercent}%`,
            right: `${100 - optimalEndPercent}%`,
          }}
        >
          <span className="optimal-min-value">{realMinOptimalValue}</span>
          <span className="optimal-max-value">{realMaxOptimalValue}</span>
        </div>
        <div
          className="gauge__ball"
          style={{ left: `${ballPositionPercent}%` }}
        />
        <span className="min-value">{realMinValue}</span>
        <span className="max-value">{realMaxValue}</span>
      </div>
    </div>
  );
};

export default BallToggle;
