import {
  User,
  FileText,
  LogOut,
  Bell,
  Video,
  ShoppingBag,
} from "react-feather";

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || "";

const getNavItems = (isAdmin) => {
  const pagesSection = [
    {
      href: "/",
      icon: FileText,
      title: "My Swings",
    },
    /*{
      href: "/lessons",
      icon: Bell,
      title: "Lessons",
    },
    {
      href: "/progress",
      icon: BarChart,
      title: "Progress",
    },
    {
      href: "/swinghistory",
      icon: RotateCcw,
      title: "Swing history",
    },
    {
      href: "/sevenstages",
      icon: BookOpen,
      title: "7 Stage coaching plans",
    }, */
    {
      href: "",
      icon: Video,
      title: "Video Lessons",
      children: [
        {
          href: "/video/stage/0",
          title: "Stage 0 - Set Up",
        },
        {
          href: "/video/stage/1",
          title: "Stage 1 - Takeaway",
        },
        {
          href: "/video/stage/2",
          title: "Stage 2 - Backswing",
        },
        {
          href: "/video/stage/3",
          title: "Stage 3 - Top of Swing",
        },
        {
          href: "/video/stage/4",
          title: "Stage 4 - Downswing",
        },
        {
          href: "/video/stage/5",
          title: "Stage 5 - Impact",
        },
        {
          href: "/video/stage/6",
          title: "Stage 6 - Follow Through",
        },
        {
          href: "/video/stage/7",
          title: "Stage 7 - Finish",
        },
      ],
    },
    {
      href: "/profile",
      icon: User,
      title: "Profile",
      children: [
        {
          href: "/profile/edit",
          title: "Edit Profile",
        },
        {
          href: "/profile/password",
          title: "Change password",
        },
        ...(STRIPE_PUBLIC_KEY
          ? [
              {
                href: "/profile/billing",
                title: "Billing",
              },
              {
                href: "/profile/payments",
                title: "Payments",
              },
            ]
          : []),
      ],
    },
    /*{
      href: "/settings",
      icon: Settings,
      title: "Settings",
    },*/
    ...(isAdmin
      ? [
          {
            href: "/admin",
            icon: Bell,
            title: "Admin",
            children: [
              {
                href: "/admin/measurements",
                title: "Measurements",
              },
              {
                href: "/admin/video",
                title: "Video",
              },
              {
                href: "/admin/users",
                title: "Users",
              },
              // ... other admin menu items
            ],
          },
        ]
      : []),
    {
      href: "/auth/sign-out",
      icon: LogOut,
      title: "Sign Out",
      action: "signOut",
    },
  ];

  return [
    {
      title: "",
      pages: pagesSection,
    },
  ];
};

export default getNavItems;
