import { gql } from "@apollo/client";

export const GET_MEASUREMENT_VIDEOS = gql`
  query GetMeasurementVideos($measurementId: ID!) {
    getVideosByMeasurementId(measurementId: $measurementId) {
      _id
      title
      description
      video
      screenshot
      stage
    }
  }
`;
