import React, { useState, createContext } from "react";

export const StoreContext = createContext(null);

const StoreProvider = ({ children }) => {
  const [dialogMeasurement, setDialogMeasurement] = useState(null);
  const [goals, setGoals] = useState([]);

  const store = {
    dialogMeasurement,
    setDialogMeasurement,
    goals,
    setGoals,
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreProvider;
