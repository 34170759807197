import React, { useEffect, useState } from "react";
import "./MeasurementImage.scss";

const fileUri = process.env.REACT_APP_BACKEND_FILE_URI;

const importStageImage = async (stage) => {
  const image = await import(`../../../media/stages/Stage-${stage}.png`);
  return image.default;
};

const importMeasurementImage = (image) =>
  `${fileUri}/download/measurements/${image}`;

const MeasurementImage = ({ picture, stage, isPopup }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const className = isPopup ? "MeasurementDialogImage" : "MeasurementImage";

  useEffect(() => {
    const loadImage = async () => {
      const src = picture
        ? importMeasurementImage(picture)
        : await importStageImage(stage);
      setImageSrc(src);
    };
    loadImage().then();
  }, [picture, stage]);

  if (!imageSrc) return null; // Render nothing if there's no image source
  return <img className={className} src={imageSrc} alt={`Stage ${stage}`} />;
};

export default MeasurementImage;
