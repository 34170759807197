import jwtDecode from "jwt-decode";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { REFRESH_TOKEN } from "../graphql/mutations/RefreshToken";
const backendUri = process.env.REACT_APP_BACKEND_URI;

const isValidToken = (token) => {
  if (!token) return false;
  return !isExpiredToken(token);
};

const getTokenType = (tokenType) => {
  return tokenType === "refresh" ? "refreshToken" : "accessToken";
};

const setSession = (tokenType, tokenValue) => {
  const tokenKey = getTokenType(tokenType);
  if (tokenValue) {
    localStorage.setItem(tokenKey, tokenValue);
  } else {
    localStorage.removeItem(tokenKey);
  }
};

const isExpiredToken = (token) => {
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp < currentTime;
};

const getSession = (tokenType) => {
  return localStorage.getItem(getTokenType(tokenType));
};

const saveTokens = (accessToken, refreshToken) => {
  setSession("access", accessToken);
  setSession("refresh", refreshToken);
};

const getValidToken = async () => {
  let accessToken = getSession("access");
  if (isValidToken(accessToken)) {
    return accessToken;
  } else {
    let refreshToken = getSession("refresh");
    if (isValidToken(refreshToken)) {
      const client = new ApolloClient({
        uri: backendUri,
        cache: new InMemoryCache(),
      });
      try {
        const { data } = await client.mutate({
          mutation: REFRESH_TOKEN,
          variables: { token: refreshToken },
        });
        accessToken = data.refreshToken.accessToken;
        refreshToken = data.refreshToken.refreshToken;
        if (isValidToken(accessToken)) {
          saveTokens(accessToken, refreshToken);
          return accessToken;
        } else {
          saveTokens(null, null);
          return "";
        }
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  }
};

export { getValidToken, saveTokens };
