import React, { useState } from "react";
import styled from "@emotion/styled";
import { Palette as PaletteIcon } from "@mui/icons-material";
import { Drawer, Fab as MuiFab } from "@mui/material";

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(8)};
  bottom: ${(props) => props.theme.spacing(8)};
  z-index: 1;
`;

function Settings() {
  const [state, setState] = useState({
    isOpen: false,
  });
}

export default Settings;
