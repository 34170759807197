import { createContext, useEffect, useReducer } from "react";
import { useApolloClient } from "@apollo/client";
import { ME } from "../graphql/queries/Me";
import { LOGIN_USER } from "../graphql/mutations/LoginUser";
import { LOGIN_USER_GOOGLE } from "../graphql/mutations/LoginUserGoogle";
import { LOGIN_USER_MICROSOFT } from "../graphql/mutations/LoginUserMicrosoft";
import { CREATE_USER } from "../graphql/mutations/CreateUser";
import { RESTORE_USER_PASSWORD } from "../graphql/mutations/RestoreUserPassword";
import { VALIDATE_USER_PASSWORD_TOKEN } from "../graphql/mutations/ValidatePasswordResetToken";
import { CHANGE_USER_PASSWORD } from "../graphql/mutations/ChangeUserPassword";
import { saveTokens } from "../utils/jwt";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const apolloClient = useApolloClient();

  const resetPassword = async (email) => {
    try {
      await apolloClient.mutate({
        mutation: RESTORE_USER_PASSWORD,
        variables: { email },
      });
      return true;
    } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
    }
  };

  const validatePasswordResetToken = async (token) => {
    try {
      await apolloClient.mutate({
        mutation: VALIDATE_USER_PASSWORD_TOKEN,
        variables: { token },
      });
      return true;
    } catch (error) {
      console.error("Error validating password reset token:", error);
      throw error;
    }
  };

  const changePassword = async (token, password) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CHANGE_USER_PASSWORD,
        variables: { token, password },
      });
      const { accessToken, refreshToken, user } = data.changeUserPassword;
      saveTokens(accessToken, refreshToken);
      dispatch({
        type: SIGN_IN,
        payload: {
          user,
        },
      });
    } catch (error) {
      console.error("Error changing password:", error);
      throw error;
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const { data } = await apolloClient.query({ query: ME });
        const user = data.me; // Adjust according to your data structure
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } catch (error) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [apolloClient]);

  const signIn = async (email, password) => {
    const { data } = await apolloClient.mutate({
      mutation: LOGIN_USER,
      variables: { input: { email, password } },
    });
    const { accessToken, refreshToken, user } = data.loginUser;
    saveTokens(accessToken, refreshToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signInGoogle = async (token) => {
    const { data } = await apolloClient.mutate({
      mutation: LOGIN_USER_GOOGLE,
      variables: { token },
    });
    const { accessToken, refreshToken, user } = data.loginUserGoogle;
    saveTokens(accessToken, refreshToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signInMicrosoft = async (token) => {
    const { data } = await apolloClient.mutate({
      mutation: LOGIN_USER_MICROSOFT,
      variables: { token },
    });
    const { accessToken, refreshToken, user } = data.loginUserMicrosoft;
    saveTokens(accessToken, refreshToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signUp = async (userData) => {
    try {
      // Use the CREATE_USER mutation to register the user
      await apolloClient.mutate({
        mutation: CREATE_USER,
        variables: { input: userData },
      });

      // After successful registration, perform a sign-in operation
      await signIn(userData.email, userData.password);
    } catch (error) {
      throw error;
    }
  };

  const signOut = async () => {
    saveTokens(null, null);
    dispatch({ type: SIGN_OUT });
  };

  const updateUser = async (updatedUserData) => {
    dispatch({
      type: "UPDATE_USER", // You can add a new case in your reducer
      payload: {
        user: updatedUserData,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signInGoogle,
        signInMicrosoft,
        signUp,
        signOut,
        updateUser,
        resetPassword,
        validatePasswordResetToken,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
